/*
 * @Author: your name
 * @Date: 2019-11-25 16:44:59
 * @LastEditTime: 2023-10-26 14:53:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \management\src\api\api.js
 */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import router from '../router/index';
import { Message } from 'element-ui';
import { baseURL } from '../utils/IPconfig'

axios.defaults.timeout = 30000;

//设置全局的请求次数，请求的间隙
axios.defaults.retry = 4;
axios.defaults.retryDelay = 500;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';  //配置请求头

// axios.defaults.baseURL = 'http://slide-work.biopic.top/webapi/';   //配置接口地址 
axios.defaults.baseURL = baseURL.ipPath;   //配置接口地址 

axios.defaults.withCredentials = false;

//添加请求拦截器
axios.interceptors.request.use(
  config => {
    // let userInfo = sessionStorage.getItem('userInfo');
    // if (userInfo != null) {
    //   let token = JSON.parse(userInfo)['token']
    //   config.headers = {
    //     'Authorization': 'bearer ' + token
    //   }
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//添加响应拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      // if (error.response.status == 401) {
      //   Message({
      //     message: 'token已过期，请重新登录！',
      //     type: 'error'
      //   })
      //   sessionStorage.removeItem("userInfo");
      //   sessionStorage.removeItem("checkCode");
      //   sessionStorage.removeItem("searchDate");
      //   router.replace({ path: '/login' })
      // }
      // if(error.response.status == 500) {
        
      // }
      // if(error.response.status == 404) {
      //   Message({
      //     message: '找不到您请求的切片文件',
      //     type: 'error'
      //   })
      // }
      return Promise.reject(error.response.data)   // 返回接口返回的错误信息
    } else {
      Message({
        message: '请求超时, 请刷新重试',
        type: 'error'
      })
      return Promise.reject(new Error('请求超时, 请刷新重试'))
    }

  });

  const service = axios.create({
    // baseURL: "http://slide-work.biopic.top/webapi/",//配置接口地址
    baseURL: baseURL.ipPath,//配置接口地址
    timeout: 20*1000,//请求超时时间，单位毫秒（这里配置的20秒）
  })

  axiosRetry(service, {//传入axios实例
    retries: 3,              // 设置自动发送请求次数
    retryDelay: (retryCount) => {
      return retryCount * 1500;  // 重复请求延迟（毫秒）
    },
    shouldResetTimeout: true,    //  重置超时时间
    retryCondition: (error) => {
      //true为打开自动发送请求，false为关闭自动发送请求
      if (error.message.includes('timeout') || error.message.includes("status code") || error.code == 500) {
        return true;
      } else {
        return false;
      };
    }
  });


//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err);
      })
      .catch((error) => {
        reject(error)
      })
  })
}
//返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: param })
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//返回一个Promise(发送delete请求)
export function fetchDelete(url, param) {
  return new Promise((resolve, reject) => {
    axios.delete(url, { data: param })
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export default {
  fetchPost,
  fetchGet,
  fetchDelete
}