/*
 * @Author: your name
 * @Date: 2021-04-25 08:37:28
 * @LastEditTime: 2023-10-26 14:50:48
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \slide-tct\src\utils\IPconfig.js
 */
export const baseURL = {
  ipPath: "http://hnpinxin-webapi.biopic.top/webapi/", //配置接口地址,
}